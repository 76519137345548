<template>
  <div class="">
    <el-dialog title="设置问卷"
               :close-on-click-modal="false"
               :visible.sync="dialogVisible"
               width="50%">
      <el-form :model="form"
               :disabled="$store.state.user.role !== 'ADMIN'"
               :rules="rules"
               label-position="top"
               ref="form">
        <el-form-item label="问题标题"
                      prop="problem">
          <el-input type="text"
                    placeholder="请输入问题标题"
                    v-model="form.problem"></el-input>

        </el-form-item>
        <el-form-item label="问题关键字"
                      prop="push_problem">
          <el-input type="text"
                    placeholder="请输入问题关键字"
                    v-model="form.push_problem"></el-input>
        </el-form-item>
        <el-form-item label="问题Id"
                      prop="problem_id">
          <el-input type="text"
                    placeholder="请输入问题id"
                    v-model="form.problem_id"></el-input>
        </el-form-item>
        <el-form-item label="是否必填"
                      prop="is_answer">
          <el-radio-group v-model="form.is_answer">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="选项列表">

          <el-row v-for="(item,index) in form.answer_config"
                  :key="index">
            <span class="mb-10">选项{{index+1}}</span>
            <div>
              <el-form-item :prop="'answer_config.' + index + '.answer'"
                            style="margin-bottom:20px"
                            :rules="{
                  required: true, message: '内容不能为空', trigger: 'blur'
                }">
                <el-input type="text"
                          placeholder="请输入选项内容"
                          v-model="item.answer"></el-input>
              </el-form-item>
              <el-form-item style="margin-bottom:20px"
                            :prop="'answer_config.' + index + '.push_answer'"
                            :rules="{
                  required: true, message: '关键字不能为空', trigger: 'blur'
                }">
                <el-input type="text"
                          placeholder="请输入关键字"
                          v-model="item.push_answer"></el-input>
              </el-form-item>
              <el-form-item style="margin-bottom:20px">
                <el-input type="text"
                          placeholder="请输入选项Id"
                          v-model="item.answer_id"></el-input>
              </el-form-item>
              <el-button type="text"
                         v-if="form.answer_config.length>2"
                         @click="delTopic(index)">删除</el-button>
              <el-button type="text"
                         v-if="index === (form.answer_config.length-1)"
                         @click="addTopic(index)">新增选项</el-button>
            </div>

          </el-row>
        </el-form-item>
        <!-- <el-form-item label="排序"
                      prop="sort">
          <el-input type="text"
                    placeholder="排序"
                    v-model.number="form.sort"></el-input>
        </el-form-item> -->
        <el-form-item class="tr">
          <el-button type="primary"
                     class="mt-15"
                     @click="submitForm('form')">立即{{form.id?'修改':'创建'}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { questionLuckySetTopic, fetchVouchers } from '@/api/market'
export default {
  data () {
    return {
      id: '', // 活动id
      dialogVisible: false,
      imgList: [],
      options: [],
      form: {
        problem: '', // 问题
        push_problem: '', // 问题关键字
        is_answer: 1,
        problem_id: '',
        answer_config: [{
          answer: '', // 问题内容
          push_answer: '',
          answer_id: ''
        }, {
          answer: '', // 问题内容
          push_answer: '',
          answer_id: ''
        }],
        sort: 0 // 排序值
      },
      rules: {
        problem: [
          { required: true, message: '请输入题目内容', trigger: 'blur' }
        ],
        push_problem: [
          { required: true, message: '请输入问题关键字', trigger: 'blur' }
        ],
        is_answer: [
          { required: true, message: '选择是否必填', trigger: 'change' }
        ],
        sort: [
          { required: true, message: '请输入排序值', trigger: 'blur' }
        ],
        answer_config: [
          { required: true, message: '请输入排序值', trigger: 'blur' }
        ]
      }
    }
  },
  components: {
  },
  methods: {
    delTopic (index) {
      this.form.answer_config.splice(index, 1)
    },
    addTopic () {
      this.form.answer_config.push({
        answer: '', // 问题内容
        push_answer: '',
        is_correct: false // 是否为正确答案
      })
    },
    async remoteMethod (query) {
      if (query !== '') {
        const res = await fetchVouchers({
          coupon_stock_id: query
        })
        if (res.meta.code === 0) {
          this.options = res.data.coupons
        }
      } else {
        this.options = []
      }
    },
    showDialog (scoped, id) {
      this.dialogVisible = true
      this.id = id
      if (scoped && scoped.row) {
        const formData = JSON.parse(JSON.stringify(scoped.row))
        this.form = formData
      } else {
        this.form = {
          problem: '', // 问题
          push_problem: '', // 问题关键字
          is_answer: 1,
          problem_id: '',
          answer_config: [{
            answer: '', // 问题内容
            push_answer: '',
            answer_id: ''
          }, {
            answer: '', // 问题内容
            push_answer: '',
            answer_id: ''
          }],
          sort: 0 // 排序值
        }
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    submitForm (formName) {
      if (this.form.push_problem === '' && this.form.problem !== '') {
        this.form.push_problem = this.form.problem
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const formData = JSON.parse(JSON.stringify(this.form))

          const res = await questionLuckySetTopic({
            activity_id: this.id, ...formData
          })
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: `${this.form.id ? '修改' : '新增'}成功`,
              type: 'success'
            })
            this.dialogVisible = false
            this.$emit('on-success')
          } else {
            this.$alert(res.meta.msg, {
              type: 'error',
              content: res.meta.msg
            })
          }
        }
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
